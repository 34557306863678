import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Outlet } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import "./index.css";
import App from "./App";
import NavBar from "components/navbar";
import CssBaseline from "@mui/material/CssBaseline";
import { Provider } from "react-redux";
import { store } from "./store";

import Cookies from "js-cookie";

const root = ReactDOM.createRoot(document.getElementById("root"));

const AppLayout = () => (
  <>
    {checkLogin && <NavBar />}
    <Outlet />
  </>
);

const getLocal = Cookies.get("theme");
const checkLogin = Cookies.get("tms_access_token");
const configMode = createTheme({
  palette: {
    mode: getLocal || "light",
  },
});
root.render(
  <Provider store={store}>
    <ThemeProvider theme={configMode}>
      <CssBaseline />
      <Router>
        <Suspense
          fallback={
            <div className="flex justify-center	items-center w-full h-screen">
              <CircularProgress />
            </div>
          }
        >
          <AppLayout />
          <App />
        </Suspense>
      </Router>
    </ThemeProvider>
  </Provider>
);
