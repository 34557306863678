// import * as Sentry from "@sentry/react";
import React, { lazy } from "react";
import { Routes, Route } from "react-router-dom";
import PrivateRoutes from "./routes/PrivateRoutes";

const Dashboard = lazy(() => import("./routes/Dashboard"));
const Platform = lazy(() => import("./routes/Platform"));
const Version = lazy(() => import("./routes/Version"));
const Text = lazy(() => import("./routes/Text"));
const Lang = lazy(() => import("./routes/Lang"));
const Remote = lazy(() => import("./routes/Remote"));
const ImageUpload = lazy(() => import("./routes/Image"));
const NotFound = lazy(() => import("./routes/NotFound"));

const Login = lazy(() => import("./routes/Login"));

function App() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />

      <Route element={<PrivateRoutes />}>
        <Route path="/" element={<Dashboard />} />
        <Route path="/tms" element={<Dashboard />} />
        <Route path="/tms/platform" element={<Platform />} />
        <Route path="/tms/platform/:platform" element={<Version />} />
        <Route path="/tms/translate/:translateId" element={<Text />} />
        <Route path="/tms/manage-language/:platform" element={<Lang />} />
        <Route path="/tms/remote-config" element={<Remote />} />
        <Route path="/upload-image" element={<ImageUpload />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}

export default App;
// export default Sentry.withProfiler(App);
