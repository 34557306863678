import { createSlice } from "@reduxjs/toolkit";

export const initialStateValue = {
  themeMode: "light",
};

export const themeSlice = createSlice({
  name: "count",
  initialState: initialStateValue,
  reducers: {
    lightMode(state) {
      state.themeMode = "light";
    },
    darkMode(state) {
      state.themeMode = "dark";
    },
  },
});

export const { lightMode, darkMode } = themeSlice.actions;
export default themeSlice.reducer;
