import * as React from "react";
import {
  Box,
  List,
  AppBar,
  Drawer,
  Toolbar,
  Collapse,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LogoutIcon from "@mui/icons-material/Logout";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

export const handleLogout = (navigate) => {
  Swal.fire({
    title: "Are you sure?",
    text: "You want to logout?",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes",
  }).then((result) => {
    if (result.isConfirmed) {
      Cookies.remove("tms_access_token");
      navigate("/Login");
      window.location.reload(false);
    }
  });
};

const menu = [
  {
    link: "/tms",
    title: "Dashboard",
    icon: <InboxIcon />,
    children: [],
  },
  {
    link: "/tms/platform",
    title: "Platform",
    icon: <InboxIcon />,
    children: [],
  },
  {
    link: "/upload-image",
    title: "Upload Image",
    icon: <InboxIcon />,
    children: [],
  },
  // {
  //   link: "/manage-language",
  //   title: "Manage Language",
  //   icon: <InboxIcon />,
  //   children: [],
  // },
  {
    link: "/tms/remote-config",
    title: "Remote config",
    icon: <InboxIcon />,
    children: [],
  },
];

const checkChild = (item, onClick) => {
  const Component = item.children.length > 0 ? MultiLevel : SingleLevel;
  return <Component item={item} onClick={onClick} />;
};

const SingleLevel = ({ item, onClick }) => {
  return (
    <Link to={item.link} onClick={onClick}>
      <ListItem>
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText primary={item.title} />
      </ListItem>
    </Link>
  );
};

const MultiLevel = ({ item, onClick }) => {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  return (
    <React.Fragment>
      <ListItem onClick={handleClick}>
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText primary={item.title} />
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {item.children.map((child, index) => (
            <React.Fragment key={index}>
              {checkChild(child, onClick)}
            </React.Fragment>
          ))}
        </List>
      </Collapse>
    </React.Fragment>
  );
};

export default function TemporaryDrawer() {
  const [state, setState] = React.useState(false);
  const themeMode = Cookies.get("theme");
  const navigate = useNavigate();

  const toggleDrawer = (open) => () => {
    setState(open);
  };

  const setTheme = () => () => {
    const mode = themeMode === "light" ? "dark" : "light";
    Cookies.set("theme", mode);
    window.location.reload(false);
  };

  const list = () => (
    <Box
      role="presentation"
      sx={{ width: 250 }}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {menu.map((row, index) => {
          return (
            <React.Fragment key={index}>
              {checkChild(row, toggleDrawer(false))}
            </React.Fragment>
          );
        })}
      </List>
    </Box>
  );

  return (
    <React.Fragment>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
            <Box sx={{ flexGrow: 1 }} />
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-haspopup="true"
              color="inherit"
              onClick={setTheme()}
            >
              {themeMode !== "dark" ? <LightModeIcon /> : <DarkModeIcon />}
            </IconButton>
            <Box ml={2} />

            <LogoutIcon onClick={() => handleLogout(navigate)} />
          </Toolbar>
        </AppBar>
      </Box>
      {state && (
        <Drawer open={state} onClose={toggleDrawer(false)}>
          {list()}
        </Drawer>
      )}
    </React.Fragment>
  );
}
